import React, { MouseEvent } from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

import styles from './DropdownInput.scss';

import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseButtonTrigger, {
    DropdownBaseButtonTriggerPropsT,
} from '../base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';

const cx = classNames.bind(styles);

export type PropsT<OptionT, ValueT> = {
    selectedValue: ValueT;
    placeholder?: string;
    isDisabled?: boolean;
    hasWarning?: boolean;
    isInline?: boolean;
    hasError?: boolean;
    options: Array<OptionT>;
    onSelect: (value: ValueT) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    overlayPosition: DropdownOverlayPositionEnum;
    renderOption: (option?: OptionT, placeholder?: string) => React.ReactNode;
    renderTrigger?: (option?: OptionT, placeholder?: string) => React.ReactNode;
    getOptionValue: (option: OptionT) => ValueT;
    className?: string;
    triggerClassName?: string;
    overlayClassName?: string;
    hasChanges?: boolean;
    renderLeftIcon?: DropdownBaseButtonTriggerPropsT['renderLeftIcon'];
    renderRightIcon?: DropdownBaseButtonTriggerPropsT['renderRightIcon'];
    onRightClick?: (event: MouseEvent<HTMLDivElement>) => void;
    separatorPositions?: Array<number>;
    hasClearControl?: boolean;
    onReset?: () => void;
    testSelector?: string;
};

const DropdownInput = <OptionT, ValueT>(props: PropsT<OptionT, ValueT>): React.ReactElement => {
    const {
        selectedValue,
        placeholder,
        onSelect,
        onFocus,
        onBlur,
        options,
        renderOption,
        renderTrigger,
        getOptionValue,
        isDisabled,
        isInline,
        hasWarning,
        hasError,
        overlayPosition,
        className,
        triggerClassName,
        overlayClassName,
        hasClearControl,
        onReset,
        hasChanges,
        renderLeftIcon,
        renderRightIcon,
        onRightClick,
        separatorPositions,
        testSelector,
    } = props;

    const separatorPositionsSet = new Set(separatorPositions);

    const [isOpen, toggleOpen] = React.useState(false);

    const handleReset = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        if (onReset) {
            onReset();
        }
    };

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }

        if (onFocus) {
            onFocus();
        }

        toggleOpen(true);
    };

    const handleClose = (): void => {
        if (onBlur) {
            onBlur();
        }

        toggleOpen(false);
    };

    const handleOuterEvent = (): void => {
        if (onBlur) {
            onBlur();
        }

        handleClose();
    };

    const selectedOption = find(options, (option) => isEqual(getOptionValue(option), selectedValue));

    const getTriggerNode = renderTrigger || renderOption;

    const fullTestSelector = `${testSelector}_dropdown`;

    const viewOptions = options.reduce<React.ReactNode[]>((viewOptions, option, index) => {
        if (separatorPositionsSet.has(index)) {
            viewOptions.push(<div key={index} className={cx('separator')} />);
        }

        const value = getOptionValue(option);

        const isSelected = selectedValue === value;

        viewOptions.push(
            <div
                key={index}
                className={cx('option', {
                    'option--isSelected': isSelected,
                })}
                data-test-selector={`${fullTestSelector}_option_${index}`}
                onClick={(): void => {
                    if (isDisabled) {
                        return;
                    }

                    onSelect(value);
                    handleClose();
                }}
            >
                {renderOption(option)}
            </div>,
        );

        return viewOptions;
    }, []);

    const isShowClearControl = !!selectedOption && hasClearControl && !!onReset;

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                <DropdownBaseButtonTrigger
                    isEmpty={!selectedOption}
                    isFocus={isOpen}
                    isDisabled={isDisabled}
                    hasChanges={hasChanges}
                    hasWarning={hasWarning}
                    hasError={hasError}
                    className={triggerClassName}
                    testSelector={fullTestSelector}
                    onClick={handleOpen}
                    renderLeftIcon={renderLeftIcon}
                    renderRightIcon={renderRightIcon}
                    onRightIconClick={onRightClick}
                    isShowClearControl={isShowClearControl}
                    onReset={handleReset}
                >
                    {getTriggerNode(selectedOption, placeholder) || placeholder}
                </DropdownBaseButtonTrigger>
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={<>{viewOptions}</>}
        />
    );
};

export default React.memo(DropdownInput) as typeof DropdownInput;
