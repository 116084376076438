import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './NotFoundPage.scss';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout/AuthLayout';
import BigSleepIcon from 'common/icons/BigSleepIcon';

const cx = classNames.bind(styles);

type PropsT = {};

const NotFoundPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t('common:page-titles.404')} />
            <AuthLayout testSelector="not-found-page">
                <div className={cx('wrap')}>
                    <div className={cx('container')}>
                        <div className={cx('icon')}>
                            <BigSleepIcon />
                        </div>
                        <div className={cx('title')}>404</div>
                        <div className={cx('description')}>{t('common:404-page.description')}</div>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
});

export default NotFoundPage;
