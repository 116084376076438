import React, { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './DropdownBaseButtonTrigger.scss';
import CloseIcon from 'common/icons/CloseIcon';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { useClickInterceptorProps } from 'common/components/ClickInterceptorLabel/hooks/use-click-interceptor-props';

const cx = classNames.bind(styles);

export type IconMetaT = {
    isEmpty: boolean;
    isFocus: boolean;
    isPressed: boolean;
    isDisabled: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    hasChanges: boolean;
    hasValue: boolean;
};

export type DropdownBaseButtonTriggerPropsT = {
    isEmpty?: boolean;
    isFocus?: boolean;
    isPressed?: boolean;
    isDisabled?: boolean;
    hasError?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
    hasChanges?: boolean;

    onClick: (event: MouseEvent<HTMLDivElement>) => void;

    testSelector?: string;
    className?: string;

    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (meta: IconMetaT) => React.ReactNode;
    onRightIconClick?: (event: MouseEvent<HTMLDivElement>) => void;

    isShowClearControl?: boolean;
    onReset?: (event: MouseEvent<HTMLDivElement>) => void;

    isMultiLine?: boolean;
};

const DropdownBaseButtonTrigger: React.FC<DropdownBaseButtonTriggerPropsT> = React.memo((props) => {
    const {
        isEmpty,
        isFocus,
        isPressed,
        isDisabled,
        className,
        onClick,
        testSelector,
        hasError,
        hasWarning,
        hasSuccess,
        hasChanges,
        children,
        renderLeftIcon,
        renderRightIcon,
        onRightIconClick,
        isShowClearControl,
        onReset,
        isMultiLine,
    } = props;

    const rigthIconClickInterceptorsProps = useClickInterceptorProps({
        onClick: onRightIconClick,
    });

    const resetClickInterceptorsProps = useClickInterceptorProps({
        onClick: onReset,
    });

    return (
        <div
            className={cs(
                cx('trigger', {
                    'trigger--isEmpty': isEmpty,
                    'trigger--isFocus': isFocus,
                    'trigger--isPressed': isPressed,
                    'trigger--isDisabled': isDisabled,
                    'trigger--hasChanges': hasChanges,
                    'trigger--hasWarning': hasWarning,
                    'trigger--hasSuccess': hasSuccess,
                    'trigger--hasError': hasError,
                    'trigger--isMultiLine': isMultiLine,
                }),
                className,
            )}
            onClick={onClick}
            data-test-selector={`${testSelector}_trigger`}
        >
            {!!renderLeftIcon && (
                <div className={cx('trigger__icon--leftIcon')}>
                    {renderLeftIcon({
                        isEmpty: !!isEmpty,
                        isFocus: !!isFocus,
                        isPressed: !!isPressed,
                        isDisabled: !!isDisabled,
                        hasError: !!hasError,
                        hasWarning: !!hasWarning,
                        hasSuccess: !!hasSuccess,
                        hasChanges: !!hasChanges,
                        hasValue: !isEmpty,
                    })}
                </div>
            )}
            <div
                className={cx('trigger__label', {
                    'trigger__label--isEmpty': isEmpty,
                    'trigger__label--isSingleLine': !isMultiLine,
                    'trigger__label--isDisabled': isDisabled,
                })}
            >
                {children}
            </div>
            {renderRightIcon && (
                <div className={cx('trigger__icon', 'trigger__icon--rightIcon')} {...rigthIconClickInterceptorsProps}>
                    {renderRightIcon({
                        isEmpty: !!isEmpty,
                        isFocus: !!isFocus,
                        isPressed: !!isPressed,
                        isDisabled: !!isDisabled,
                        hasError: !!hasError,
                        hasWarning: !!hasWarning,
                        hasSuccess: !!hasSuccess,
                        hasChanges: !!hasChanges,
                        hasValue: !isEmpty,
                    })}
                </div>
            )}
            {isShowClearControl && (
                <div
                    className={cx('trigger__icon', 'trigger__icon--clear')}
                    {...resetClickInterceptorsProps}
                    data-test-selector={`${testSelector}_reset`}
                >
                    <CloseIcon fillColor="currentColor" />
                </div>
            )}
            <div className={cx('trigger__icon')}>
                <ArrowsIcon />
            </div>
        </div>
    );
});

export default DropdownBaseButtonTrigger;
